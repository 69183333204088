function loadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/util/${view}.vue`
    );
}

const utilRoutes = [
  /* Util */

  // 로그인
  {
    path: '/loginmain',
    name: 'loginmain',
    component: loadView('LoginMain'),
    meta: {
      onlyGuest: true,
    },
  },

  // 사업자 로그인
  {
    path: '/loginbusiness',
    name: 'loginbusiness',
    component: loadView('LoginBusiness'),
    meta: {
      onlyGuest: true,
    },
  },

  // 회원가입 - 약관
  {
    path: '/signup/agreement',
    name: 'signupagreement',
    component: loadView('signupAgreement'),
    meta: {
      onlyGuest: true,
      category: ['회원가입', '사업자 회원가입'],
    },
  },
  // 회원가입 - 입력
  {
    path: '/signup/confirm',
    name: 'signupconfirm',
    component: loadView('signupConfirm'),
    meta: {
      onlyGuest: true,
      category: ['회원가입', '사업자 회원가입'],
    },
  },
  // 회원가입 - 완료
  {
    path: '/signup/result',
    name: 'signupresult',
    component: loadView('signupResult'),
    meta: {
      onlyGuest: true,
      category: ['회원가입', '사업자 회원가입'],
    },
  },

  // 전체검색
  {
    path: '/searchlist',
    name: 'searchlist',
    component: loadView('searchList'),
    meta: {
      category: ['통합검색'],
    },
  },
];

export default utilRoutes;
