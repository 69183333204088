import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import boardRoutes from './modules/board';
import periodRoutes from './modules/period';
import nonstopRoutes from './modules/nonstop';
import passesRoutes from './modules/passes';
import parkingRoutes from './modules/parking';
import paymentRoutes from './modules/payment';
import utilRoutes from './modules/util';
import mypageRoutes from './modules/mypage';
// import reductionRoutes from './modules/reduction';
import Error from '@/html/subpage/util/ErrorPage.vue';

const routes = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: '/nice-login-check',
    component: () => import('@/html/subpage/util/NiceLoginCheck.vue'),
  },
  {
    path: '/nice-login-check-business',
    component: () => import('@/html/subpage/util/NiceLoginCheckBusiness.vue'),
  },
  {
    path: '/nice-login-find-id',
    component: () => import('@/html/subpage/util/NiceLoginCheckFindId.vue'),
  },
  {
    path: '/nice-login-change-password',
    component: () => import('@/html/subpage/util/NiceLoginCheckChangePw.vue'),
  },
  {
    path: '/main',
    name: 'main',
    component: () =>
      import(
        /* webpackChunkName: "view-main-vue" */ '@/html/mainpage/mainPage'
      ),
    meta: { category: ['메인', '메인'] },
  },
  ...periodRoutes,
  ...boardRoutes,
  ...nonstopRoutes,
  ...passesRoutes,
  ...parkingRoutes,
  // ...reductionRoutes,
  ...paymentRoutes,
  ...utilRoutes,
  ...mypageRoutes,
  // Not Found Page
  {
    path: '/404',
    name: 'notFound',
    component: Error,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // 페이지 새로고침 시 스크롤을 최상단으로 이동
    return { top: 0 };
  },
});

// 라우터 이동할 때 최상단으로 스크롤하는 함수
function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
}

router.beforeEach((to, from, next) => {
  const isLogin = store.state.isLogin;
  const empDiv = store.state.empDiv;
  const { empcategory } = to.meta;

  if (to.matched.some(record => record.meta.authorization)) {
    if (!isLogin) {
      next('/login');
      return;
    }
  }

  if (to.matched.some(record => record.meta.empcategory)) {
    if (!empcategory.includes(empDiv)) {
      if (empDiv === 'USR') {
        alert('해당서비스는 사업자 회원만 이용할 수 있습니다.');
        next('/main');
        return;
      }
      if (empDiv === 'BSN') {
        alert('해당서비스는 일반 회원만 이용할 수 있습니다.');
        next('/main');
        return;
      }
      alert('로그인 후 이용 해 주세요.');
      next('/loginmain');
      return;
    }
  }

  if (to.matched.some(record => record.meta.onlyGuest)) {
    if (isLogin) {
      return next({ path: '/main' });
    }
  }

  next();
});

router.afterEach((to, from) => {
  // 스크롤 위치를 최상단으로 이동
  window.scrollTo(scrollBehavior(to, from, null));
});

export default router;
