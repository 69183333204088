function generalLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/parkingService/generalPeriod/${view}.vue`
    );
}

// function vulneralbleLoadView(view) {
//   return () =>
//     import(
//       /* webpackChunkName: "view-[request]" */ `@/html/subpage/parkingService/vulnerablePeriod/${view}.vue`
//     );
// }

function overnightLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/parkingService/overnightPeriod/${view}.vue`
    );
}

function freightLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/parkingService/freightPeriod/${view}.vue`
    );
}

const periodRoutes = [
  /* 주차서비스 > 공영 정기권 */
  // 안내
  {
    path: '/generalperiod/info',
    name: 'generalperiodinfo',
    component: generalLoadView('periodInfo'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 안내 및 신청'],
    },
  },
  // 약관
  {
    path: '/generalperiod/agreement',
    name: 'generalperiodagreement',
    component: generalLoadView('periodAgreement'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 정보입력
  {
    path: '/generalperiod/confirm',
    name: 'generalperiodconfirm',
    component: generalLoadView('periodConfirm'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 정보확인
  {
    path: '/generalperiod/check',
    name: 'generalperiodcheck',
    component: generalLoadView('periodCheck'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 완료
  {
    path: '/generalperiod/result',
    name: 'generalperiodresult',
    component: generalLoadView('periodResult'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 이용현황
  {
    path: '/generalperiod/history',
    name: 'generalperiodhistory',
    component: generalLoadView('periodHistory'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 상세
  {
    path: '/generalperiod/view',
    name: 'generalperiodview',
    component: generalLoadView('periodView'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 해지
  {
    path: '/generalperiod/cancel',
    name: 'generalperiodcancel',
    component: generalLoadView('periodCancel'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 해지완료
  {
    path: '/generalperiod/cancel/result',
    name: 'generalperiodcancelresult',
    component: generalLoadView('periodCancelResult'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 정기권 잔여면 안내
  {
    path: '/generalperiod/remain',
    name: 'generalperiodremain',
    component: generalLoadView('periodRemain'),
    meta: {
      category: ['주차서비스', '공영 정기권', '정기권 잔여면 안내'],
      empcategory: ['USR'],
    },
  },

  // /* 주차서비스 > 어르신/임산부 정기권 */
  // // 안내
  // {
  //   path: '/vulnerableperiod/info',
  //   name: 'vulnerableperiodinfo',
  //   component: vulneralbleLoadView('vulnerablePeriodInfo'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 안내 및 신청'],
  //   },
  // },
  // // 약관
  // {
  //   path: '/vulnerableperiod/agreement',
  //   name: 'vulnerableperiodagreement',
  //   component: vulneralbleLoadView('vulnerablePeriodAgreement'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 안내 및 신청'],
  //     empcategory: ['USR'],
  //   },
  // },
  // // 정보입력
  // {
  //   path: '/vulnerableperiod/confirm',
  //   name: 'vulnerableperiodconfirm',
  //   component: vulneralbleLoadView('vulnerablePeriodConfirm'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 안내 및 신청'],
  //     empcategory: ['USR'],
  //   },
  // },
  // // 정보확인
  // {
  //   path: '/vulnerableperiod/check',
  //   name: 'vulnerableperiodcheck',
  //   component: vulneralbleLoadView('vulnerablePeriodCheck'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 안내 및 신청'],
  //     empcategory: ['USR'],
  //   },
  // },
  // // 완료
  // {
  //   path: '/vulnerableperiod/result',
  //   name: 'vulnerableperiodresult',
  //   component: vulneralbleLoadView('vulnerablePeriodResult'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 안내 및 신청'],
  //     empcategory: ['USR'],
  //   },
  // },
  // // 이용현황
  // {
  //   path: '/vulnerableperiod/history',
  //   name: 'vulnerableperiodhistory',
  //   component: vulneralbleLoadView('vulnerablePeriodHistory'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 신청내역 확인'],
  //     empcategory: ['USR'],
  //   },
  // },
  // // 상세
  // {
  //   path: '/vulnerableperiod/view',
  //   name: 'vulnerableperiodview',
  //   component: vulneralbleLoadView('vulnerablePeriodView'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 신청내역 확인'],
  //     empcategory: ['USR'],
  //   },
  // },
  // // 해지
  // {
  //   path: '/vulnerableperiod/cancel',
  //   name: 'vulnerableperiodcancel',
  //   component: vulneralbleLoadView('vulnerablePeriodcancel'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 신청내역 확인'],
  //     empcategory: ['USR'],
  //   },
  // },
  // // 해지완료
  // {
  //   path: '/vulnerableperiod/cancel/result',
  //   name: 'vulnerableperiodcancelresult',
  //   component: vulneralbleLoadView('vulnerablePeriodcancelResult'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 신청내역 확인'],
  //     empcategory: ['USR'],
  //   },
  // },
  // // 정기권 잔여면 안내
  // {
  //   path: '/vulnerableperiod/remain',
  //   name: 'vulnerableperiodremain',
  //   component: vulneralbleLoadView('vulnerablePeriodRemain'),
  //   meta: {
  //     category: ['주차서비스', '어르신/임산부 정기권', '정기권 잔여면 안내'],
  //     empcategory: ['USR'],
  //   },
  // },

  /* 주차서비스 > 밤샘노상주차장 정기권 */
  // 안내
  {
    path: '/overnightperiod/info',
    name: 'overnightperiodinfo',
    component: overnightLoadView('overnightPeriodInfo'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 안내 및 신청'],
    },
  },
  // 약관
  {
    path: '/overnightperiod/agreement',
    name: 'overnightperiodagreement',
    component: overnightLoadView('overnightPeriodAgreement'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 정보입력
  {
    path: '/overnightperiod/confirm',
    name: 'overnightperiodconfirm',
    component: overnightLoadView('overnightPeriodConfirm'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 정보확인
  {
    path: '/overnightperiod/check',
    name: 'overnightperiodcheck',
    component: overnightLoadView('overnightPeriodCheck'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 완료
  {
    path: '/overnightperiod/result',
    name: 'overnightperiodresult',
    component: overnightLoadView('overnightPeriodResult'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 이용현황
  {
    path: '/overnightperiod/history',
    name: 'overnightperiodhistory',
    component: overnightLoadView('overnightPeriodHistory'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 상세
  {
    path: '/overnightperiod/view',
    name: 'overnightperiodview',
    component: overnightLoadView('overnightPeriodView'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 해지
  {
    path: '/overnightperiod/cancel',
    name: 'overnightperiodcancel',
    component: overnightLoadView('overnightPeriodCancel'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 해지완료
  {
    path: '/overnightperiod/cancel/result',
    name: 'overnightperiodcancelresult',
    component: overnightLoadView('overnightPeriodCancelResult'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 정기권 잔여면 안내
  {
    path: '/overnightperiod/remain',
    name: 'overnightperiodremain',
    component: overnightLoadView('overnightPeriodRemain'),
    meta: {
      category: ['주차서비스', '밤샘노상주차장 정기권', '정기권 잔여면 안내'],
      empcategory: ['USR'],
    },
  },

  /* 주차서비스 > 화물공영차고지 정기권 */
  // 안내
  {
    path: '/freightperiod/info',
    name: 'freightperiodinfo',
    component: freightLoadView('freightPeriodInfo'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 안내'],
    },
  },
  // 약관
  {
    path: '/freightperiod/agreement',
    name: 'freightperiodagreement',
    component: freightLoadView('freightPeriodAgreement'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 정보입력
  {
    path: '/freightperiod/confirm',
    name: 'freightperiodconfirm',
    component: freightLoadView('freightPeriodConfirm'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 정보확인
  {
    path: '/freightperiod/check',
    name: 'freightperiodcheck',
    component: freightLoadView('freightPeriodCheck'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 완료
  {
    path: '/freightperiod/result',
    name: 'freightperiodresult',
    component: freightLoadView('freightPeriodResult'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 안내 및 신청'],
      empcategory: ['USR'],
    },
  },
  // 이용현황
  {
    path: '/freightperiod/history',
    name: 'freightperiodhistory',
    component: freightLoadView('freightPeriodHistory'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 상세
  {
    path: '/freightperiod/view',
    name: 'freightperiodview',
    component: freightLoadView('freightPeriodView'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 해지
  {
    path: '/freightperiod/cancel',
    name: 'freightperiodcancel',
    component: freightLoadView('freightPeriodCancel'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 해지완료
  {
    path: '/freightperiod/cancel/result',
    name: 'freightperiodcancelresult',
    component: freightLoadView('freightPeriodCancelResult'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 신청내역 확인'],
      empcategory: ['USR'],
    },
  },
  // 정기권 잔여면 안내
  {
    path: '/freightperiod/remain',
    name: 'freightperiodremain',
    component: freightLoadView('freightPeriodRemain'),
    meta: {
      category: ['주차서비스', '화물공영차고지 정기권', '정기권 잔여면 안내'],
      empcategory: ['USR'],
    },
  },
];

export default periodRoutes;
