function loadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/payment/${view}.vue`
    );
}

const paymentRoutes = [
  /* 주차요금정산 */

  // 주차요금 사전정산 - 안내
  {
    path: '/prepaymentinfo',
    name: 'prepaymentinfo',
    component: loadView('prepaymentInfo'),
    meta: {
      category: ['주차요금정산', '주차요금 사전정산', '사전정산안내'],
    },
  },
  // 주차요금 사전정산 - 조회
  {
    path: '/prepaymentsearch',
    name: 'prepaymentsearch',
    component: loadView('prepaymentSearch'),
    meta: {
      category: ['주차요금정산', '주차요금 사전정산', '조회/결제'],
    },
  },
  // 주차요금 사전정산 - 상세
  {
    path: '/prepaymentview',
    name: 'prepaymentview',
    component: loadView('prepaymentView'),
    meta: {
      category: ['주차요금정산', '주차요금 사전정산', '조회/결제'],
    },
  },

  // 미납주차요금 - 안내
  {
    path: '/unpaidinfo',
    name: 'unpaidinfo',
    component: loadView('unpaidInfo'),
    meta: {
      category: ['주차요금정산', '미납주차요금', '미납주차요금 안내'],
    },
  },
  // 미납주차요금 - 조회/결제
  {
    path: '/unpaidsearch',
    name: 'unpaidsearch',
    component: loadView('unpaidSearch'),
    meta: {
      category: ['주차요금정산', '미납주차요금', '조회/결제'],
    },
  },
];

export default paymentRoutes;
