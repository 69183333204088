<template>
  <div v-show="showModal">
    <section class="alert check">
      <article class="alertCont">
        <div class="inner">
          <slot name="content"></slot>
        </div>
      </article>
      <article class="alertBottom">
        <slot name="button"></slot>
      </article>
    </section>
    <section class="dim">
      <slot name="dimarea"></slot>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: true,
    };
  },
};
</script>
