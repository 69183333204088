function loadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/mypage/${view}.vue`
    );
}

const mypageRoutes = [
  /* 마이페이지 */

  // 1. 일반 마이페이지
  {
    path: '/mypage/general',
    name: 'mypagegeneral',
    component: loadView('MypageGeneral'),
    meta: {
      category: ['마이페이지', '회원정보'],
      empcategory: ['USR', 'ADMIN'],
    },
  },

  // 2. 사업자 마이페이지
  {
    path: '/mypage/business',
    name: 'mypagebusiness',
    component: loadView('MypageBusiness'),
    meta: {
      category: ['마이페이지', '회원정보'],
      empcategory: ['BSN'],
    },
  },

  // 2. 사업자 정보 확인
  {
    path: '/mypage/businesslogin',
    name: 'mypagebusinesslogin',
    component: loadView('MypageBusinessLogin'),
    meta: {
      category: ['마이페이지', '사업자 정보 수정'],
      empcategory: ['BSN'],
    },
  },

  // 2. 사업자 정보 수정
  {
    path: '/mypage/businessupdate',
    name: 'mypagebusinessupdate',
    component: loadView('MypageBusinessUpdate'),
    meta: {
      category: ['마이페이지', '사업자 정보 수정'],
      empcategory: ['BSN'],
    },
  },
];

export default mypageRoutes;
