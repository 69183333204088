<template>
  <footer id="footer">
    <div class="top">
      <div class="inner">
        <ul class="link">
          <li>
            <button title="회원약관" @click="openMembershipModal">
              <b>회원약관</b>
            </button>
          </li>
          <li>
            <button title="개인정보처리방침" @click="openPersonal">
              <b>개인정보처리방침</b>
            </button>
          </li>
          <li>
            <button
              title="영상정보처리기기운영방침"
              class="gray"
              @click="openVideoInfo"
            >
              영상정보처리기기운영방침
            </button>
          </li>
        </ul>
        <div class="famSelect">
          <label for="familySite" class="hidden">부산시 패밀리 사이트</label>
          <select
            title="패밀리사이트 선택"
            id="familySite"
            @change="goExternalSite"
            @keydown.up="eventStem($event)"
            @keydown.right="eventStem($event)"
            @keydown.down="eventStem($event)"
            @keydown.left="eventStem($event)"
            ref="moveSite"
          >
            <option value="/main" selected>FAMILY SITE</option>
            <option value="https://www.bisco.or.kr/">부산시설공단</option>
            <option value="https://www.bisco.or.kr/facil01/index.asp">
              공원시설
            </option>
            <option value="https://www.bisco.or.kr/facil02/index.asp">
              교통시설
            </option>
            <option value="http://www.duribal.co.kr/main/main.html">
              사회복지
            </option>
            <option value="https://www.bisco.or.kr/facil04/index.asp">
              상가시설
            </option>
            <option value="https://www.bisco.or.kr/facil05/index.asp">
              장사시설
            </option>
            <option value="https://www.bisco.or.kr/facil06/index.asp">
              체육시설
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="inner">
        <h1 class="logo">
          <div class="flogoImg">부산시설공단통합주차포털</div>
        </h1>
        <address>
          <ul>
            <li>주소 : (47132) 부산광역시 부산진구 새싹로 174(연지동)</li>
            <li>
              <p>전화번호 : 051-860-7700.7744</p>
              <p>대표자명 : 이성림</p>
              <p>사업자등록번호 : 605-82-05242</p>
            </li>
          </ul>
        </address>
        <p class="copyright">
          Copyright ⓒ 2010 Busan Infrastructure Corporation. All rights
          Reserved.
        </p>
      </div>
    </div>

    <a class="btnTop" @click="scrollTop">
      <i class="icon"></i><i class="icon2"></i><span>TOP</span>
    </a>

    <show-modal v-if="showMembershipModal">
      <template #info> 회원약관</template>
      <template #content>
        <article>
          <div class="terms">
            <strong>제1장 목적</strong>
            <div>
              <strong>제1조 (목적)</strong>
              <p>
                본 약관은 부산광역시 시설공단(이하 ‘부산시설공단’)이 제공하는
                모든 웹 서비스(이하 ‘서비스’)를 이용함에 있어 부산시설공단과
                이용자의 권리, 의무, 책임사항 및 이용조건, 절차와 기타 필요한
                사항에 대하여 규정함을 목적으로 합니다.
              </p>
            </div>
            <div>
              <strong>제2조 (약관의 명시와 개정)</strong>
              <p>
                부산시설공단은 이 약관의 내용과 주소지, 관리자의 성명,
                개인정보보호 담당자의 성명, 연락처(전화, 팩스, 전자우편 주소
                등)를 이용자가 알 수 있도록 홈페이지에 게시합니다.
              </p>
              <p>
                부산시설공단은「약관의규제에관한법률」,「전자거래기본법」,「전자서명법」,「정보통신망이용촉진및정보보호이용등에관한법률」,「방문판매등에관한법률」,「소비자보호법」등
                관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
              </p>
              <p>
                부산시설공단은 약관을 개정할 경우에는 적용일자 및 개정사유를
                명시하여 현행약관과 함께 우리 공단 홈페이지의 초기화면에 그
                적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
              </p>
              <p>
                본 약관에 동의하는 것은 정기적으로 웹을 방문하여 약관의
                변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한
                정보를 알지 못해 발생하는 이용자의 피해는 부산시설공단에서
                책임지지 않습니다.
              </p>
              <p>
                본 약관에 명시되지 않은
                사항은「전기통신기본법」,「전기통신사업법」,「정보통신윤리위원회심의규정」,「정보통신
                윤리강령」,「프로그램보호법 및 기타 관련 법령」의 규정에
                의합니다.
              </p>
            </div>
            <div>
              <strong>제3조 (정의)</strong>
              <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
              <p>
                이 용 자 : 본 약관에 따라 부산시설공단이 제공하는 서비스를 받는
                자.
              </p>
              <p>
                이용계약 : 서비스 이용과 관련하여 부산시설공단과 이용자간에
                체결하는 계약.
              </p>
              <p>
                가 입 : 부산시설공단이 제공하는 신청서 양식에 해당 정보를
                기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위
              </p>
              <p>
                회 원 : 부산시설공단 홈페이지 회원가입에 필요한
                개인정보(실명확인)를 제공하여 회원 등록을 완료한 사람.
              </p>
              <p>
                회원 ID : 회원간의 식별과 서비스 이용을 위하여 이용자가 선정하고
                부산시설공단이 승인하는 영문자와 숫자의 조합.
              </p>
              <p>
                비밀번호 : 회원의 신원 확인 및 통신상의 개인정보 보호를 위하여
                이용자 자신이 설정한 문자와 숫자의 조합
              </p>
              <p>
                이용해지 : 부산시설공단 또는 회원이 이용계약을 종료시키는 행위.
              </p>
              <p>
                본 약관에서 정의하지 않은 용어는 개별서비스에 대한 별도약관 및
                이용규정에서 정의합니다.
              </p>
            </div>
            <strong>제2장 서비스 이용계약</strong>
            <div>
              <strong>제3조 (이용계약의 성립)</strong>
              <p>
                이용계약은 부산시설공단 홈페이지의 회원이 되고자하는 이용자의
                이용신청에 대한 부산시설공단의 이용승낙으로 성립됩니다.
              </p>
              <p>
                본 이용약관에 대한 동의는 회원 신청 시 해당 페이지의 '동의'
                버튼을 누름으로써 의사표시를 합니다.
              </p>
            </div>
            <div>
              <strong>제4조 (이용신청 및 승낙)</strong>
              <p>
                이용신청은 부산시설공단 홈페이지 회원정보 화면에서 가입 희망자가
                부산시설공단에서 제공하는 가입신청서 양식에 개인의 신상정보를
                기록하여 신청할 수 있습니다.
              </p>
              <p>
                회원 가입 희망자가 신청서 사항을 정확히 기재하여 이용신청을
                하였을 경우 특별한 사정이 없는 한 서비스 이용신청을 승낙합니다.
              </p>
            </div>
            <div>
              <strong>제5조 (회원가입)</strong>
              <p>
                회원가입은 신청자가 온라인으로 당 사이트에서 제공하는 소정의
                가입신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는
                것으로 성립됩니다.
              </p>
            </div>
            <div>
              <strong>제6조 (ID 부여 및 변경)</strong>
              <p>부산시설공단은 이용자의 신청에 따라 회원 ID를 부여합니다.</p>
              <p>
                회원 ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경
                하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.
              </p>
              <p>
                회원 ID 및 비밀번호의 관리책임은 이용자에게 있습니다. 이를
                소홀히 관리하여 발생하는 손해 또는 제3자에 의한 부정이용 등에
                대한 책임은 회원에게 있으며 부산시설공단은 그에 따른 책임을 일절
                지지 않습니다.
              </p>
              <p>
                기타 이용자 개인정보 관리 및 변경 등에 관한 사항은
                부산시설공단이 정하는 바에 의합니다.
              </p>
            </div>
            <div>
              <strong>제7조 (회원정보 사용에 대한 동의)</strong>
              <p>
                회원의 개인정보에 대해서는 부산시설공단의 개인정보 보호정책이
                적용됩니다.
              </p>
              <p>
                2. 부산시설공단의 회원 정보는 다음과 같이 수집, 사용, 관리,
                보호됩니다.
              </p>
              <p>
                - 개인정보의 수집 : 서비스 가입시 회원 정보를 통하여 기본 정보를
                수집합니다.
              </p>
              <p>
                - 개인정보의 사용 : 부산시설공단은 홈페이지 서비스 제공과
                관련해서 수집된 회원의 정보를 본인의 승낙 없이 제3자에게 누설,
                배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해
                국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나
                정보통신윤리위원회의 요청이 있는 경우 또는 기타 관계법령에서
                정한 절차에 따른 요청이 있는 경우, 회원이 부산시설공단
                홈페이지에 제공한 개인정보를 스스로 공개한 경우에는 그러하지
                않습니다.
              </p>
              <p>
                - 개인정보의 관리 : 회원은 개인정보의 보호 및 관리를 위하여
                서비스의 개인정보관리에서 수시로 귀하의 개인정보를 수정 및
                삭제할 수 있습니다.
              </p>
              <p>
                - 개인정보의 보호 : 회원의 개인정보는 본인만이 열람·수정·삭제 할
                수 있으며, 이는 전적으로 회원의 ID와 비밀번호에 의해 관리되고
                있습니다.
              </p>
              <p>
                회원이 서비스 약관에 따라 이용신청을 하는 것은 신청서에 기재된
                회원정보를 수집, 이용하는 것에 동의하는 것으로 간주됩니다.
              </p>
              <p>
                회원으로 등록된 개인정보 자료는 부산시설공단에서 관리하는
                사업소별 홈페이지(부산시설공단(본사), 영락공원, 광안대로,
                어린이대공원, 금강공원, 중앙공원, 태종대유원지, 공영주차장,
                자갈치시장, 도시고속도로, 터널지하차도, 국제지하도상가,
                용두산공원)와 연계되어 사용될 수도 있습니다.
              </p>
            </div>
            <div>
              <strong>제8조 (사용자의 정보 보안)</strong>
              <p>
                회원이 부산시설공단 홈페이지 서비스 가입 절차를 완료하는
                순간부터 회원 정보의 비밀을 유지할 책임이 있으며, ID와
                비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 회원에게
                있습니다.
              </p>
              <p>
                ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며 ID나
                비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시
                부산시설공단으로 신고하여야 합니다. 신고를 하지 않음으로 인해
                발생하는 모든 책임은 회원 본인에게 있습니다.
              </p>
              <p>
                이용자는 부산시설공단 홈페이지 서비스의 사용 종료 후 정확히
                로그아웃 해야 하며, 로그아웃하지 아니하여 제3자가 귀하에 관한
                정보를 도용하는 등의 결과로 인해 발생하는 손해 및 손실에 대하여
                부산시설공단은 책임을 부담하지 아니합니다.
              </p>
            </div>
            <strong>제3장 서비스의 이용</strong>
            <div>
              <strong>제9조 (서비스 이용)</strong>
              <p>
                부산시설공단은 회원의 이용신청을 승낙한 일로부터 서비스를
                개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터
                개시합니다.
              </p>
              <p>
                부산시설공단의 업무상 또는 기술 및 시스템상 장애로 인하여
                서비스를 제공하지 못하는 경우에는 홈페이지에 공지하거나 회원에게
                통보합니다.
              </p>
              <p>
                서비스 이용시간은 연중무휴 1일 24시간을 원칙으로 하며 정기점검
                등의 필요시에는 부산시설공단에서 정한 날 또는 시간에 서비스를
                중지할 수 있습니다.
              </p>
              <p>
                회원에 가입한 후라도 일부 서비스는 특정 회원(추모의 집 회원,
                시민 모니터, 안전문화운동 회원)에게만 제공할 수도 있습니다.
              </p>
            </div>
            <div>
              <strong>제10조 (서비스의 변경, 중지 및 정보 사용)</strong>
              <p>
                본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의
                내용이 국가의 비상사태, 정전, 부산시설공단의 관리범위 외의
                서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나
                삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실에
                대해 부산시설공단은 아무런 책임을 지지 않음에 동의합니다.
              </p>
              <p>
                부산시설공단이 정상적인 서비스 제공의 어려움으로 인하여
                일시적으로 서비스를 중지하여야 할 경우에는 서비스 중지 1주일
                전에 공지 후 서비스를 중지할 수 있으며, 이 기간동안 회원이
                고지내용을 인지하지 못한데 대하여 부산시설공단은 책임을 부담하지
                아니합니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나
                전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지
                못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의
                손실이 있을 경우에 대하여도 부산시설공단은 책임을 부담하지
                아니합니다.
              </p>
              <p>
                부산시설공단의 사정으로 서비스를 영구적으로 중단하여야 할 경우
                제2항을 준용합니다. 다만, 이 경우 사전 고지기간은 1개월로
                합니다.
              </p>
              <p>
                부산시설공단은 사전 공지 후 서비스를 일시적으로 수정, 변경 및
                중단할 수 있으며, 이에 대하여 회원 또는 제3자에게 어떠한 책임도
                부담하지 아니합니다.
              </p>
              <p>
                부산시설공단은 회원이 이 약관의 내용에 위배되는 행동을 한 경우,
                임의로 서비스 사용을 중지할 수 있습니다. 이 경우 부산시설공단은
                회원의 접속을 금지할 수 있으며, 회원이 게시한 내용의 전부 또는
                일부를 임의로 삭제할 수 있습니다.
              </p>
              <p>
                장기간 휴면 회원인 경우 안내 메일 또는 공지사항 발표 후
                1주일간의 통지 기간을 거쳐 서비스 사용을 중지할 수 있습니다.
              </p>
            </div>
            <div>
              <strong>제11조 (정보 제공 및 광고 게재)</strong>
              <p>
                부산시설공단은 서비스를 운영함에 있어 필요하다고 인정되는 다양한
                정보 및 광고를 전자우편이나 서신우편, SMS(문자 서비스) 등으로
                귀하에게 제공할 수 있습니다.
              </p>
              <p>
                부산시설공단은 서비스에 적절하다고 판단되거나 공익성이 있는
                홍보물을 게재할 수 있습니다.
              </p>
            </div>
            <div>
              <strong>제12조 (게시물의 관리 및 운영)</strong>
              <p>
                회원이 게시한 게시물의 내용에 대한 권리는 회원에게 있습니다.
              </p>
              <p>
                부산시설공단은 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는
                권리를 보유하며, 다음과 같은 경우 사전 통지 없이 삭제할 수
                있습니다.
              </p>
              <p>
                - 본 이용 약관에 위배되거나 불법, 음란, 저속하다고 판단되는 내용
              </p>
              <p>
                - 타인을 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
              </p>
              <p>- 공공질서 및 미풍양속에 위반되는 내용인 경우</p>
              <p>- 범죄적 행위에 결부된다고 인정되는 내용일 경우</p>
              <p>- 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</p>
              <p>- 기타 관계 법령에 위배되는 경우</p>
              <p>
                회원의 게시물이 타인의 저작권을 침해함으로써 발생하는 모든
                민형사상의 책임은 전적으로 회원에게 있습니다.
              </p>
            </div>
            <strong>제4장 계약해지</strong>
            <div>
              <strong>제13조 (계약해지 및 이용제한)</strong>
              <p>
                회원이 이용계약을 해지하고자 할 때는 회원 본인이 직접 홈페이지를
                통해 탈퇴 신청을 하여야 합니다.
              </p>
              <p>
                회원의 정보가 허위로 판명되거나, 허위가 있다고 의심할 만한
                합리적인 사유가 발생할 경우 부산시설공단은 귀하의 본 서비스
                사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는
                불이익에 대해 책임을 부담하지 아니합니다.
              </p>
              <p>
                부산시설공단은 다음 각 호에 해당하는 회원가입에 대하여는 가입을
                취소할 수 있습니다.
              </p>
              <p>- 타인의 명의 및 정보를 도용하여 신청하였을 때</p>
              <p>- 회원가입 신청서의 내용을 허위로 기재 및 신청하였을 때</p>
              <p>- 사회 질서 및 미풍양속 저해, 범죄적 행위에 관련된 경우</p>
              <p>
                - 기타 관련 법령이나 부산시설공단이 정한 조건에 위배되는 경우
              </p>
            </div>
            <strong>제5장 의무 및 책임</strong>
            <div>
              <strong>제14조 (부산시설공단의 의무)</strong>
              <p>
                부산시설공단은 법령과 본 약관이 금지하거나 미풍양속에 반하는
                행위를 하지 않으며, 지속적, 안정적으로 서비스를 제공할 의무가
                있습니다.
              </p>
              <p>
                부산시설공단은 회원의 개인 신상 정보를 본인의 승낙 없이 타인에게
                누설, 배포하지 않습니다. 다만, 전기통신관련법령 등 관계법령에
                의하여 관계 국가기관 등의 요구가 있는 경우에는 예외 적용합니다.
              </p>
              <p>
                부산시설공단은 이용자가 안전하게 우리 공단 홈페이지서비스를
                이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한
                보안시스템을 갖추어야 합니다.
              </p>
            </div>
            <div>
              <strong>제15조 (회원의 의무)</strong>
              <p>
                회원 가입 시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한
                가입정보에 대하여 변경이 발생할 경우 귀하에 대한 정보가 정확한
                정보가 되도록 유지, 갱신하여야 합니다.
              </p>
              <p>
                회원 본인의 ID 및 비밀번호 분실 또는 과실로 인한 손해에 대해
                부산시설공단은 책임을 지지 않습니다.
              </p>
              <p>
                회원은 부산시설공단의 사전 승낙 없이 서비스를 이용한 영리행위는
                일제 할 수 없습니다.
              </p>
              <p>
                회원은 부산시설공단 홈페이지 서비스 이용과 관련하여 다음과 같은
                행위를 할 수 없습니다.
              </p>
              <p>- 다른 회원의 비밀번호와 ID를 도용하여 부정 사용하는 행위</p>
              <p>- 음란, 모욕적, 위협적이거나 타인에게 위해를 가하는 행위</p>
              <p>- 서비스를 통하여 전송된 내용의 출처를 위장하는 행위</p>
              <p>
                - 타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을
                침해하는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로
                전송하는 행위
              </p>
              <p>
                - 부산시설공단의 승인을 받지 않고 광고, 도배글, 정크 및 스팸
                메일, 행운의 편지를 게재, 전자우편 또는 기타의 방법으로 전송하는
                행위
              </p>
              <p>- 다른 사용자의 개인정보를 수립 또는 저장하는 행위</p>
              <p>- 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</p>
              <p>
                - 선량한 풍속, 해킹행위 또는 컴퓨터바이러스 및 기타 사회질서를
                해하는 행위
              </p>
              <p>- 타인의 명예를 훼손하거나 모욕하는 행위</p>
              <p>
                - 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의
                행위
              </p>
              <p>
                회원은 이 약관 및 관계법령에서 규정한 사항을 준사하여야 합니다.
              </p>
            </div>
            <strong>제6장 기 타</strong>
            <div>
              <strong>제16조 (소유권)</strong>
              <p>
                부산시설공단 홈페이지에서 제공하는 서비스, 소프트웨어, 이미지,
                마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된
                지적재산권 및 기타 권리는 부산시설공단에 소유권이 있습니다.
              </p>
              <p>
                회원은 부산시설공단에서 명시적으로 승인한 경우를 제외하고는
                서비스 내용에 대한 수정, 대여, 판매, 배포, 제작, 양도,
                재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며,
                제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.
              </p>
            </div>
            <div>
              <strong>제17조 (양도금지)</strong>
              <p>
                회원은 서비스의 이용권한, 기타 이용계약에 관한 지위를 타인에게
                양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
              </p>
            </div>
            <strong>제7장 손해배상 등</strong>
            <div>
              <strong>제18조 (손해배상)</strong>
              <p>
                부산시설공단은 무료로 제공되는 서비스와 관련하여 회원에게 어떠한
                손해가 발생하더라도 부산시설공단의 중대한 과실 및 고의로 행한
                범죄행위에 의한 경우를 제외하고 이에 대하여 책임을 부담하지
                아니합니다.
              </p>
            </div>
            <div>
              <strong>제19조 (면책조항)</strong>
              <p>
                부산시설공단은 천재지변, 전쟁 또는 이에 준하는 불가항력으로
                인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한
                책임이 면제됩니다.
              </p>
              <p>
                부산시설공단은 기간통신 사업자가 전기통신 서비스를 중지하거나
                정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이
                면제됩니다.
              </p>
              <p>
                부산시설공단은 회원의 귀책사유로 인한 서비스이용의 장애 및
                손해에 대하여 책임을 지지 않습니다.
              </p>
              <p>
                부산시설공단은 어떠한 경우라도 회원이 서비스에 담긴 정보에
                의존해 얻은 이득이나 입은 손해에 대하여 책임이 없으며, 회원이
                서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지
                않습니다.
              </p>
              <p>
                부산시설공단은 우리 공단 홈페이지가 제공한 서비스가 아닌 가입자
                또는 기타 유관기관이 제공하는 서비스의 내용상의 정확성, 완전성
                및 질에 대하여 보장하지 않습니다. 따라서 부산시설공단은 회원이
                서비스를 이용하며 타 이용자로 인해 입게 되는 정신적 피해에
                대하여 보상할 책임을 지지 않습니다.
              </p>
            </div>
            <div>
              <strong>제20조 (관할법원)</strong>
              <p>
                부산시설공단 홈페이지 서비스 이용과 관련하여 부산시설공단과 회원
                사이에 분쟁이 발생한 경우, 서로간 원만한 해결을 위해 필요한 모든
                노력을 하여야 합니다.
              </p>
              <p>
                제1항에도 불구하고 소송이 제기될 경우 부산광역시 소재지를
                관할하는 법원을 관할 법원으로 합니다.
              </p>
            </div>
            <strong>부칙</strong>
            <p>본 약관은 2007년 02월 12일부터 시행합니다.</p>
          </div>
        </article>
      </template>
      <template #button>
        <button class="btn closeBtn" @click="closeBtn">
          <span class="hidden">닫기</span>
        </button>
      </template>
    </show-modal>
  </footer>
</template>

<script>
import showModal from '@/components/PopupType1.vue';

export default {
  data() {
    return {
      showMembershipModal: false,
      showPersonalModal: false,
    };
  },

  components: {
    showModal,
  },

  methods: {
    goExternalSite() {
      if (
        /Mobi/i.test(window.navigator.userAgent) ||
        /Safari/i.test(window.navigator.userAgent)
      ) {
        location.href = this.$refs.moveSite.value;
      } else {
        window.open(this.$refs.moveSite.value, '_blank');
      }
    },

    goFamilySite(e) {
      if (e.target.value != '' && e.target.value != null) {
        this.goExternalSite(e.target.value);
      }
    },

    eventStem(e) {
      switch (e.keyCode) {
        case 37:
          e.preventDefault();
          break;
        case 38:
          e.preventDefault();
          break;
        case 39:
          e.preventDefault();
          break;
        case 40:
          e.preventDefault();
          break;
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },

    openMembershipModal() {
      this.showMembershipModal = true;
    },

    closeBtn() {
      this.showMembershipModal = false;
    },

    openPersonal() {
      window.open('https://www.bisco.or.kr/news/news04/news04_5/');
    },

    openVideoInfo() {
      window.open('https://www.bisco.or.kr/news/news04/news04_6/');
    },
  },
};
</script>
