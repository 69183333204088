<template>
  <article class="errorForm">
    <article class="errorBox">
      <div class="txtArea">
        <img
          :src="require('@/assets/images/common/img-error.svg')"
          alt="ERROR 이미지"
        />
        <strong>Page Not Found</strong>
        <p>요청하신 페이지를 찾을수가 없습니다.</p>
      </div>
      <div class="btnArea">
        <button type="button" class="btn blue" @click="$router.push('main')">
          메인으로
        </button>
      </div>
    </article>
  </article>
</template>

<script>
export default {};
</script>
