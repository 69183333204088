import axiosHook from '../axios';

export default {
  // 로그인
  login(params) {
    return axiosHook('post', '/api/actionLogin.json', params);
  },

  // 로그아웃
  logout() {
    return axiosHook('post', '/api/actionLogout.json');
  },

  // 아이디 중복체크
  findId(empNo) {
    return axiosHook('get', '/api/member/find-duplicate-id', {
      checkId: empNo,
    });
  },

  // 사업자 회원가입
  signupBusiness(params, sameRequest) {
    return axiosHook(
      'post',
      `/api/member/bsn?sameRequest=${sameRequest}`,
      params,
    );
  },

  changePassword(params) {
    return axiosHook('post', `/api/login/change-password/business`, params);
  },

  // 사업자번호 확인
  checkBusinessLicense(companyNo, headers) {
    return axiosHook(
      'post',
      'https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=nXhVcVp047ifed9EV2otIf5ldhb45mkumzoJdPfpwmt38iJE8rydBP9V7DPkljuV9dff5MmxuAJxb8f0MsaewA%3D%3D',
      { b_no: [companyNo] },
      headers,
    );
  },

  findBusinessLicense(companyNo) {
    return axiosHook('post', '/api/member/bsn/findCompanyNumber', {
      companyNo: companyNo,
    });
  },
};
