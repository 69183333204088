import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';
import store from './store';
import VueDaumPostcode from 'vue-daum-postcode';
import VueCookies from 'vue-cookies';
import FilterUtils from './util/filters';
import vuetify from './plugins/vuetify';

import '@/assets/sass/components/_button.scss';
import '@/assets/sass/components/_common.scss';
import '@/assets/sass/components/_table.scss';
import '@/assets/sass/layout/_contents.scss';
import '@/assets/sass/layout/_footer.scss';
import '@/assets/sass/layout/_header.scss';
import '@/assets/sass/layout/_mainpage.scss';

const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(store)
  .use(VueDaumPostcode)
  .use(VueCookies);

app.$cookies.config('1d');

app.config.globalProperties.$filters = FilterUtils;

app.mount('#app');
