import axios from 'axios';
import router from '@/routers/index.js';
import store from '@/store/index';

const axiosHook = async (
  method,
  url,
  params,
  config,
  dontNeedAlert,
  customTimeOut,
  customRetryTime,
  isRetry,
  isFinalRetry,
) => {
  const timeout = customTimeOut || process.env.VUE_APP_HTTP_REQUEST_TIME_OUT;
  let retryTime = 3;

  if (customRetryTime) {
    retryTime = customRetryTime;
  } else if (customRetryTime === 0) {
    retryTime = 0;
  }

  let response = null;

  const instance = axios.create({
    timeout: timeout,
  });

  try {
    switch (method) {
      case 'put':
        response = await instance.put(url, params, config);
        break;
      case 'patch':
        response = await instance.patch(url, params, config);
        break;
      case 'post':
        response = await instance.post(url, params, config);
        break;
      case 'get':
        response = await instance.get(url, {
          params,
          ...config,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    if (isRetry) {
      if (isFinalRetry && !dontNeedAlert) {
        alert('잠시 후 다시 시도해주세요.');
      } else if (isFinalRetry) {
        return 'finalIsEnd';
      }
      return;
    }

    const errorRes = error?.response;
    if (error.code === 'ECONNABORTED') {
      for (let i = 1; i <= retryTime; i++) {
        const isLastRetry = i === retryTime;
        response = await axiosHook(
          method,
          url,
          params,
          config,
          dontNeedAlert,
          customTimeOut,
          customRetryTime,
          true,
          isLastRetry,
        );
        if (response) {
          if (isSessionExpired(response)) {
            routerMove('/main');
          }
          return response;
        }
      }
    } else if (errorRes) {
      const errorCodeStart = Math.trunc(errorRes.status / 100);
      if (errorCodeStart === 4) {
        alert('잘못된 요청입니다.');
      } else if (errorCodeStart === 5) {
        alert('잠시 후 다시 시도해주세요.');
      }
    }
    return;
  }

  if (!isSessionExpired(response)) {
    routerMove('/main');
  }

  return response?.data;
};

const isSessionExpired = response => {
  let result = true;
  if (response?.data?.sessionExpired) {
    if (!store.state.checkSessionExpired) {
      alert('다시 로그인 해주세요.');
      store.dispatch('checkSessionExpired', true);
      store.dispatch('logout', undefined);
      result = false;
    }
  }

  return result;
};

const routerMove = route => {
  router.push(route).catch(err => {
    if (
      err.name !== 'NavigationDuplicated' &&
      !err.message.includes('Avoided redundant navigation to current location')
    ) {
      return;
    }
  });
};

export default axiosHook;
