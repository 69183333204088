<template>
  <div class="topBanner" v-if="showTopBanner">
    <div class="inner">
      <swiper
        class="topBannerSlide"
        :slides-per-view="1"
        :autoplay="{
          delay: 4000,
          disableOnInteraction: false,
        }"
        :navigation="{
          prevEl: '.topBanner .swiper-button-prev',
          nextEl: '.topBanner .swiper-button-next',
        }"
        :modules="modules"
        @swiper="setBannerSwiper"
      >
        <swiper-slide v-for="(item, index) in topBannerList" :key="index">
          <div :style="{ background: item.background }">
            <img :src="item.imgSrc" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="btnControl">
        <button class="swiper-button-prev"></button>
        <button
          v-show="bannerStop"
          class="swiper-button-stop"
          @click="bannerStopSwiper"
        ></button>
        <button
          v-show="bannerPlay"
          class="swiper-button-play"
          @click="bannerPlaySwiper"
        ></button>
        <button class="swiper-button-next"></button>
      </div>
      <div class="check">
        <p class="checkbox">
          <input type="checkbox" id="chk" class="hidden" v-model="check" />
          <label for="chk">오늘 하루 열지 않음</label>
        </p>
        <button class="bannerClose" @click="closeTopBanner">
          <span class="hidden">배너 닫기</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import api from '@/api/modules/main';
import { isEmpty } from 'lodash';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    const bannerSwiper = ref(null);
    const setBannerSwiper = swiper => {
      bannerSwiper.value = swiper;
    };

    return {
      modules: [Autoplay, Navigation],
      bannerSwiper,
      setBannerSwiper,
    };
  },

  data() {
    return {
      bannerStop: true,
      bannerPlay: false,
      showTopBanner: false,
      check: false,
      topBannerAttachList: [],
      topBannerList: [],
    };
  },

  created() {
    this.getTopBannerList();
  },

  methods: {
    async getTopBannerList() {
      const res = await api.getTopBannerConutents();
      this.setTopBannerList(res.topBannerList);
      this.setAttachList(res.attachRes);
      this.checkImg(this.topBannerAttachList, this.topBannerList);
    },

    setTopBannerList(data) {
      if (!data) {
        return;
      }
      if (!data.result) {
        return;
      }

      this.topBannerList = data.data || [];

      this.getTopBannerCookie();

      if (isEmpty(this.topBannerList)) {
        this.showTopBanner = false;
      }
    },

    setAttachList(data) {
      if (!data) {
        return;
      }
      if (!data.result) {
        return;
      }

      data.data.map(item => {
        this.topBannerList.map(v => {
          if (item.bannerId === v.bannerId) {
            this.topBannerAttachList.push(item);
          }
        });
      });
    },

    checkImg(attachList, props) {
      for (let i = 0; i < attachList.length; i++) {
        const repoId = attachList[i].repoId;
        const repoPath = attachList[i].repoPath;
        const filePath = attachList[i].filePath;
        const saveNm = attachList[i].saveNm;
        const fileExt = attachList[i].fileExt;

        let srcArr = [];
        srcArr[i] =
          '/api/banner/attach/' +
          repoId +
          repoPath +
          '/' +
          filePath +
          '/' +
          saveNm +
          '.' +
          fileExt;

        props[i].imgSrc = srcArr[i];
      }
    },

    bannerStopSwiper() {
      this.bannerSwiper.autoplay.stop();
      this.bannerStop = false;
      this.bannerPlay = true;
    },

    bannerPlaySwiper() {
      this.bannerSwiper.autoplay.start();
      this.bannerStop = true;
      this.bannerPlay = false;
    },

    closeTopBanner() {
      if (this.check) {
        const today = new Date();
        const cookieKey = 'PORTAL-TOPBANNER-COOKIE';
        this.$cookies.set(
          cookieKey,
          'false',
          new Date(today.getFullYear, today.getMonth + 1, today.getDay)
            .toUTCString,
        );
      }
      this.showTopBanner = false;
    },

    getTopBannerCookie() {
      const cookie = this.$cookies.get('PORTAL-TOPBANNER-COOKIE');
      if (cookie === 'false') {
        this.showTopBanner = false;
      } else {
        this.showTopBanner = true;
      }
    },
  },
};
</script>
