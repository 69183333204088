<template>
  <div v-if="$route.name == 'main' || $route.fullPath == '/'">
    <top-banner />
    <gnb-area />
    <router-view />
    <footer-area />
  </div>
  <div v-else-if="$route.meta.category">
    <gnb-area></gnb-area>
    <section id="category">
      <div class="inner">
        <h2 @touchstart="closeTopNavi">{{ $route.meta.category[0] }}</h2>
        <top-navigation ref="TopNavigation"></top-navigation>
      </div>
    </section>
    <router-view @touchstart="closeTopNavi" />
    <footer-area />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import GnbArea from '@/html/layout/GnbArea.vue';
import TopBanner from '@/html/layout/TopBanner.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import FooterArea from '@/html/layout/FooterArea.vue';
import { isArray, isEmpty } from 'lodash';

export default {
  watch: {
    async $route(to, from) {
      const isQueryStringEmpty = isEmpty(to.query);
      if (from.path === '/' && isQueryStringEmpty) {
        this.setIsRefreshed(undefined);
      } else if (from.path === to.path && isQueryStringEmpty) {
        const childComponents = to.matched;
        if (isArray(childComponents)) {
          await this.$nextTick();
          const instanceComponent = childComponents[0].instances?.default;
          if (instanceComponent?.init) {
            this.$store.dispatch('isRefreshed', true);
            instanceComponent.init();
          }
        }
      }
    },
  },

  beforeCreate() {
    window.addEventListener('beforeunload', () => this.setIsRefreshed(true));
  },

  components: {
    GnbArea,
    TopBanner,
    TopNavigation,
    FooterArea,
  },

  methods: {
    closeTopNavi() {
      this.$refs.TopNavigation.closeTopNavi();
    },

    setIsRefreshed(status) {
      this.$store.dispatch('isRefreshed', status);
    },
  },
};
</script>
