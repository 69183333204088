import axiosHook from '../axios';
import axios from 'axios';

export default {
  async getMainContents() {
    const urls = [
      '/api/parking/realtime/list',
      '/api/parking/inout-count',
      '/api/popup/getPopupList',
      '/api/banner/getBannerAttach',
      '/api/banner/list?bannerType=visual&useYn=Y',
      '/api/banner/list?bannerType=normal&useYn=Y',
      '/api/board/notice/list?pageSize=6',
      '/api/board/bid/list?pageSize=6',
    ];
    const req = urls.map(url => axiosHook('get', url, null, null, true));
    const res = await Promise.all(req);
    const [
      parkingList,
      countData,
      popupList,
      attachData,
      visualList,
      bannerList,
      noticeList,
      bidList,
    ] = await Promise.all(res);
    const item = {
      parkingList,
      countData,
      popupList,
      attachData,
      visualList,
      bannerList,
      noticeList,
      bidList,
    };

    return item;
  },

  async getTopBannerConutents() {
    const urls = [
      '/api/banner/list?bannerType=top&useYn=Y',
      '/api/banner/getBannerAttach',
    ];
    const req = urls.map(url => axiosHook('get', url));
    const res = await Promise.all(req);
    const [topBannerList, attachRes] = await Promise.all(res);
    const item = {
      topBannerList,
      attachRes,
    };

    return item;
  },

  // 전기차 면수 조회
  getChaeviChargerList(params) {
    return axios.get(
      `https://api.chaevi.co.kr/api/ChargerV1/chargersbyrgn/${params}`,
      {
        headers: {
          apikey: 'E01F5C82-472C-465B-8A3F-0E5AA14E6312',
          'App-Package-Name': 'com.region.BUSAN',
        },
      },
    );
  },
};
