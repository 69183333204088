<template>
  <section id="APP">
    <main-layout></main-layout>
  </section>
</template>
<script>
import MainLayout from './html/layout/MainLayout.vue';

export default {
  components: {
    MainLayout,
  },
};
</script>
