function LoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/parkingLots/${view}.vue`
    );
}

const parkingRoutes = [
  /* 주차장 */
  // 실시간 주차현황
  {
    path: '/realtimeparking',
    name: 'realtimeparking',
    component: LoadView('realtimeParking'),
    meta: {
      category: ['주차장', '실시간 주차현황'],
    },
  },

  // 주차장 검색
  {
    path: '/searchparking',
    name: 'searchparking',
    component: LoadView('searchParking'),
    meta: {
      category: ['주차장', '주차장 검색'],
    },
  },
  // 주차장 검색 - 상세
  {
    path: '/searchparkingview',
    name: 'searchparkingview',
    component: LoadView('searchParkingView'),
    meta: {
      category: ['주차장', '주차장 검색'],
    },
  },

  /* 주차장 > 요금안내 */
  // 주차요금
  {
    path: '/parkingfeeinfo',
    name: 'parkingfeeinfo',
    component: LoadView('parkingFeeInfo'),
    meta: {
      category: ['주차장', '요금안내', '주차요금'],
    },
  },
  // 할인정책
  {
    path: '/parkingdiscount',
    name: 'parkingdiscount',
    component: LoadView('parkingDiscount'),
    meta: {
      category: ['주차장', '요금안내', '할인정책'],
    },
  },
  // 급지구분기준
  {
    path: '/parkinggrade',
    name: 'parkinggrade',
    component: LoadView('parkingGrade'),
    meta: {
      category: ['주차장', '요금안내', '급지구분기준'],
    },
  },
];

export default parkingRoutes;
