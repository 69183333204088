/* eslint-disable */
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import menuJson from './menuJson.json';

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    menuList: menuJson.menuList,
    isLogin: undefined,
    empNo: undefined,
    empDiv: undefined,
    empNm: undefined,
    empMobile: undefined,
    authorCode: undefined,
    checkTerms: undefined,
    checkSessionExpired: undefined,
    tempValue: undefined,
    isRefreshed: undefined,
  },
  getters: {
    getMenuList: state => state.menuList,
  },
  mutations: {
    isLogin: function (state, payload) {
      state.isLogin = payload;
    },
    logout: function (state, payload) {
      state.isLogin = false;
      state.empNo = payload;
      state.empDiv = payload;
      state.empNm = payload;
      state.empMobile = payload;
      state.menuList = menuJson.menuList;
    },
    empNo: function (state, payload) {
      state.empNo = payload;
    },
    empDiv: function (state, payload) {
      state.empDiv = payload;
    },
    empNm: function (state, payload) {
      state.empNm = payload;
    },
    empMobile: function (state, payload) {
      state.empMobile = payload;
    },
    authorCode: function (state, payload) {
      state.authorCode = payload;
    },
    checkTerms: function (state, payload) {
      state.checkTerms = payload;
    },
    checkSessionExpired: function (state, payload) {
      state.checkSessionExpired = payload;
    },
    tempValue: function (state, payload) {
      state.tempValue = payload;
    },
    isRefreshed: function (state, payload) {
      state.isRefreshed = payload;
    },
  },
  actions: {
    isLogin: function (context, payload) {
      context.commit('isLogin', payload);
    },
    logout: function (context, payload) {
      context.commit('logout', payload);
    },
    empNo: function (context, payload) {
      context.commit('empNo', payload);
    },
    empDiv: function (context, payload) {
      context.commit('empDiv', payload);
    },
    empNm: function (context, payload) {
      context.commit('empNm', payload);
    },
    empMobile: function (context, payload) {
      context.commit('empMobile', payload);
    },
    authorCode: function (context, payload) {
      context.commit('authorCode', payload);
    },
    checkTerms: function (context, payload) {
      context.commit('checkTerms', payload);
    },
    checkSessionExpired: function (context, payload) {
      context.commit('checkSessionExpired', payload);
    },
    tempValue: function (context, payload) {
      context.commit('tempValue', payload);
    },
    isRefreshed: function (context, payload) {
      context.commit('isRefreshed', payload);
    },
  },
  modules: {},
});
