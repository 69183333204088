<template>
  <div>
    <!-- alertType: check -->
    <alert-check v-show="showCheckModal">
      <template #content>
        <pre>{{ propsMsg }}</pre>
        <article class="btnArea">
          <button class="btn blue" ref="checkRef" @click="closeModal">
            확인
          </button>
        </article>
      </template>
      <template #button>
        <button class="btn closeBtn" @click="closeModal">
          <span class="hidden">닫기</span>
        </button>
      </template>
    </alert-check>

    <!-- alertType: warning -->
    <alert-warning v-show="showWarningModal">
      <template #content>
        <pre>{{ propsMsg }}</pre>
        <article class="btnArea">
          <button class="btn blue" ref="warningRef" @click="closeModal">
            확인
          </button>
        </article>
      </template>
      <template #button>
        <button class="btn closeBtn" @click="closeModal">
          <span class="hidden">닫기</span>
        </button>
      </template>
    </alert-warning>

    <!-- alertType: confirm -->
    <alert-check v-show="showConfirmModal">
      <template #content>
        <pre>{{ propsMsg }}</pre>
        <article class="btnArea">
          <button
            style="padding: 0 30px"
            class="btn border"
            @click="cancelModal"
          >
            {{ propsConfirmCancelMessage }}
          </button>
          <button
            style="padding: 0 30px"
            class="btn blue"
            ref="confirmRef"
            @click="confirmCheckModal"
          >
            {{ propsConfirmOkMessage }}
          </button>
        </article>
      </template>
      <template #button>
        <button class="btn closeBtn" @click="closeModal">
          <span class="hidden">닫기</span>
        </button>
      </template>
    </alert-check>
  </div>
</template>

<script>
import AlertCheck from '@/components/AlertCheck.vue';
import AlertWarning from '@/components/AlertWarning.vue';

export default {
  props: [
    'msg',
    'alertType',
    'url',
    'confirmCancelMessage',
    'confirmOkMessage',
  ],

  data() {
    return {
      propsMsg: '',
      propsConfirmCancelMessage: '',
      propsConfirmOkMessage: '',
      showCheckModal: false,
      showWarningModal: false,
      showConfirmModal: false,
    };
  },

  components: {
    AlertCheck,
    AlertWarning,
  },

  watch: {
    alertType() {
      this.matchType();
    },
  },

  mounted() {
    this.matchType();
  },

  methods: {
    matchType() {
      if (this.alertType === 'warning') {
        this.showWarningModal = true;
        this.propsMsg = this.msg;
      }
      if (this.alertType === 'check') {
        this.showCheckModal = true;
        this.propsMsg = this.msg;
      }
      if (this.alertType === 'confirm') {
        this.showConfirmModal = true;
        this.propsMsg = this.msg;
        this.propsConfirmCancelMessage = this.confirmCancelMessage
          ? this.confirmCancelMessage
          : '취소';
        this.propsConfirmOkMessage = this.confirmOkMessage
          ? this.confirmOkMessage
          : '확인';
      }
    },
    closeModal() {
      this.showCheckModal = false;
      this.showWarningModal = false;
      this.showConfirmModal = false;
      this.propsMsg = '';
      this.setAlertData();
      if (this.url) {
        this.$router.push(this.url);
      }
      this.$emit('focus');
    },

    confirmCheckModal() {
      this.showConfirmModal = false;
      this.propsMsg = '';
      this.setAlertData(true);
      this.$emit('focus');
    },

    cancelModal() {
      this.showConfirmModal = false;
      this.propsMsg = '';
      this.setAlertData();
      this.$emit('focus');
    },

    setAlertData(confirm) {
      this.$emit('setAlertData', confirm);
    },

    focusButton() {
      if (this.showCheckModal) {
        this.$refs.checkRef.focus();
      }
      if (this.showWarningModal) {
        this.$refs.warningRef.focus();
      }
      if (this.showConfirmModal) {
        this.$refs.confirmRef.focus();
      }
    },
  },
};
</script>
