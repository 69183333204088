<template>
  <div class="web">
    <ul class="breadCrumb">
      <li>HOME</li>
      <li>
        <p class="pageTxt">{{ firTitle }}</p>
        <ul class="depth">
          <li v-for="(item, index) in firMenuList" :key="index">
            <router-link :to="item.link">{{ item.title }}</router-link>
          </li>
        </ul>
      </li>
      <li v-if="secTitle !== ''">
        <p class="pageTxt">{{ secTitle }}</p>
        <ul class="depth">
          <li v-for="(item, index) in secMenuList" :key="index">
            <router-link
              :to="item.link"
              :class="{
                link: item.link === '/customer',
              }"
              >{{ item.title }}</router-link
            >
          </li>
        </ul>
      </li>
      <li v-if="trdTitle !== ''">
        <p class="pageTxt">{{ trdTitle }}</p>
        <ul class="depth">
          <li v-for="(item, index) in trdMenuList" :key="index" class="blod">
            <router-link :to="item.link">{{ item.title }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="mobile">
    <div class="breadCrumbMobile">
      <div v-if="trdTitle === ''">
        <p
          class="pageTxt"
          :class="{ clicked: mobileTopNavi }"
          @click="showTopNavi"
        >
          {{ secTitle }}
        </p>
        <div v-show="mobileTopNavi">
          <ul class="depth">
            <li v-for="(item, index) in secMenuList" :key="index">
              <router-link
                :to="item.link"
                :class="{
                  link: item.link === '/customer',
                }"
                >{{ item.title }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div v-if="trdTitle !== ''">
        <p
          class="pageTxt"
          :class="{ clicked: mobileTopNavi }"
          @click="showTopNavi"
        >
          {{ trdTitle }}
        </p>
        <div v-show="mobileTopNavi">
          <ul class="depth">
            <li v-for="(item, index) in trdMenuList" :key="index">
              <router-link
                :to="item.link"
                :class="{
                  link: item.link === '/customer',
                }"
                >{{ item.title }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  computed: {
    ...mapGetters({
      menuList: 'getMenuList',
    }),
    isLogin() {
      return this.$store.state.isLogin;
    },
    empDiv() {
      return this.$store.state.empDiv;
    },
  },

  data() {
    return {
      firMenuList: [],
      secMenuList: [],
      trdMenuList: [],
      firTitle: '',
      secTitle: '',
      trdTitle: '',

      mobileTopNavi: false,
    };
  },

  created() {
    this.getTitle();
  },

  watch: {
    $route() {
      this.getTitle();
      this.closeTopNavi();
    },
  },

  methods: {
    showTopNavi() {
      this.mobileTopNavi = !this.mobileTopNavi;
    },

    closeTopNavi() {
      this.mobileTopNavi = false;
    },

    getTitle() {
      this.firTitle = this.$route.meta.category[0];
      this.secTitle = this.$route.meta.category[1];
      if (this.$route.meta.category[2]) {
        this.trdTitle = this.$route.meta.category[2];
      } else {
        this.trdTitle = '';
      }
      this.getSnbMenuList();
    },

    getSnbMenuList() {
      const copy = cloneDeep(this.menuList);
      const recursion = (menu = []) => {
        for (let i = 0; i < menu.length; i++) {
          if (!this.isLogin) {
            if (menu[i].link.includes('mypage')) {
              menu[i] = '';
            }
          } else {
            // 관리자 예외
            if (this.empDiv === 'ADMIN') {
              if (menu[i].link === '/mypage/business') menu[i] = '';
            } else {
              if (this.empDiv === 'BSN') {
                if (menu[i].title === '주차서비스') {
                  menu[i].link = '/parkingpasses/info';
                }
              }
              if (menu[i].auth !== this.empDiv && menu[i].auth !== undefined) {
                menu[i] = '';
              }
            }
          }

          recursion(menu[i].menuList);
        }
      };
      recursion(copy);

      this.firMenuList = copy.filter(v => {
        if (v.menuList) {
          v.menuList = v.menuList.filter(i => i != '');
        }
        return v;
      });

      for (let j = 0; j < copy.length; j++) {
        if (copy[j].title === this.firTitle) {
          this.secMenuList = copy[j].menuList;
          copy[j].menuList.map(item => {
            if (item.title === this.secTitle) {
              this.trdMenuList = item.menuList;
            }
          });
        }
      }

      // 예외
      if (this.secTitle === '사업자 회원가입') {
        this.secMenuList = [
          {
            title: '사업자 회원가입',
            link: '/signup/agreement',
            selectMenu: false,
          },
        ];
      }
      if (this.firTitle === '통합검색') {
        this.firMenuList = [
          {
            title: '통합검색',
            link: 'searchlist',
            selectMenu: false,
          },
        ];
        this.secTitle = '';
      }
    },
  },
};
</script>
