<template>
  <article>
    <!--바로가기 navi-->
    <div id="accNav">
      <router-link to="#content">본문 바로가기</router-link>
      <router-link to="#footer">푸터 바로가기</router-link>
    </div>

    <!-- mobile -->
    <header id="mobile">
      <div class="inner" v-if="!btnmenu">
        <h1 class="logo">
          <router-link to="/main">
            <div class="logoImg">부산시설공단<br />통합주차포털</div>
          </router-link>
        </h1>

        <input
          type="text"
          id="search"
          title="검색어 입력"
          placeholder="검색어 입력"
          v-model="searchKeyword"
          v-if="searchBarShow"
          @emitHideModal="searchBarShow = false"
          @keyup.enter="search"
          @focus="focused"
          @blur="focusOut"
        />
        <div class="searchBar">
          <label for="search" class="hidden">검색어를 입력하세요.</label>
          <button type="button" class="button searchBtn" @click="searchBar">
            <span class="hidden">검색</span>
          </button>
          <button
            type="button"
            class="button closeBtn"
            v-if="searchBarShow"
            @click="searchBarClose"
          >
            <span class="hidden">닫기</span>
          </button>
        </div>

        <button type="button" @click="menubtn" class="moTopMenu">
          <span class="hidden">메뉴</span>
        </button>
      </div>
      <div class="inner gnbOn" v-else>
        <h1 class="logo">
          <router-link to="/main">
            <div class="logoImg">부산시설공단<br />통합주차포털</div>
          </router-link>
        </h1>
        <button type="button" @click="menubtn" class="closeMenu">
          <span class="hidden">메뉴닫기</span>
        </button>
      </div>

      <nav class="gnb" v-show="btnmenu">
        <article class="gnbHead">
          <div class="utill">
            <!-- Logout -->
            <ul v-if="isLogin">
              <li class="welcome">
                <span>{{ empNm }}</span
                >님
              </li>
              <li>
                <button type="button" class="btn blue" @click="actionLogout">
                  로그아웃
                </button>
              </li>
            </ul>

            <!-- Login -->
            <ul v-else>
              <li>
                <button
                  type="button"
                  class="btn blue"
                  title="로그인"
                  @click="this.$router.push('/loginmain')"
                >
                  로그인
                </button>
              </li>
            </ul>
          </div>
        </article>
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, index) in firDepth" :key="index">
            <v-expansion-panel-title>
              {{ item.title }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(secDepth, index) in item.menuList"
                  :key="index"
                >
                  <v-list v-if="secDepth.menuList === undefined">
                    <v-list-item>
                      <v-list-item-content>
                        <router-link :to="secDepth.link">
                          {{ secDepth.title }}
                        </router-link>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-expansion-panel-title
                    v-if="secDepth.menuList !== undefined"
                    class="subMenu"
                  >
                    {{ secDepth.title }}
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-list>
                      <v-list-item
                        v-for="(trdDepth, index) in secDepth.menuList"
                        :key="index"
                      >
                        <v-list-item-content>
                          <router-link :to="trdDepth.link">
                            {{ trdDepth.title }}
                          </router-link>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </nav>
    </header>
    <!-- mobile end -->

    <!-- pc -->
    <header id="web" :class="{ hover: isHovering }">
      <div class="inner">
        <h1 class="logo">
          <router-link to="/main">
            <div class="logoImg">
              부산시설공단<br class="mobile" />통합주차포털
            </div>
          </router-link>
        </h1>

        <!--gnb-->
        <nav class="gnb">
          <ul class="depth01">
            <li
              v-for="(item, index) in firDepth"
              :key="index"
              @click="modalHide"
              @mouseover="openGnbmenu(item)"
              @mouseleave="closeGnbmenu(item)"
              :class="{ hover: item.showMenu }"
            >
              <router-link :to="item.link">{{ item.title }}</router-link>
              <article class="gnbDiv" v-if="item.showMenu">
                <ul class="depth02">
                  <li v-for="(secDepth, index) in item.menuList" :key="index">
                    <router-link
                      :to="secDepth.link"
                      :class="{
                        link: secDepth.link === '/customer',
                      }"
                      >{{ secDepth.title }}</router-link
                    >
                    <ul class="depth03">
                      <li
                        v-for="(trdDepth, index) in secDepth.menuList"
                        :key="index"
                      >
                        <router-link :to="trdDepth.link">{{
                          trdDepth.title
                        }}</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </article>
            </li>
          </ul>
        </nav>
        <!--//gnb-->

        <article class="utill">
          <!-- Logout -->
          <div v-if="isLogin">
            <span>{{ empNm }} 님</span>
            <button type="button" class="btn logout" @click="actionLogout">
              로그아웃
            </button>
          </div>

          <!-- Login -->
          <div v-else>
            <router-link to="/loginmain" class="btn blue login" title="로그인"
              >로그인</router-link
            >
          </div>
          <!-- Logout -->

          <div class="searchBar">
            <label for="search" class="hidden">검색어를 입력하세요.</label>
            <input
              type="text"
              id="search"
              title="검색어 입력"
              placeholder="검색어 입력"
              v-model="searchKeyword"
              v-if="searchBarShow"
              @emitHideModal="searchBarShow = false"
              @keyup.enter="search"
              @focus="focused"
              @blur="focusOut"
            />
            <button type="button" class="button searchBtn" @click="searchBar">
              <span class="hidden">검색</span>
            </button>
            <button
              type="button"
              class="button closeBtn"
              v-if="searchBarShow"
              @click="searchBarClose"
            >
              <span class="hidden">닫기</span>
            </button>
          </div>

          <button class="siteMapBtn" @click="siteMapBtn">
            <span class="hidden">사이트맵</span>
          </button>
        </article>
      </div>
    </header>

    <!--sitemap-->
    <div class="sitemap" v-show="siteMap">
      <div class="siteHead">
        <h2>SITE MAP</h2>
        <button class="sitemapClose" @click="siteMapBtn">
          <span class="hidden">사이트맵 닫기</span>
        </button>
      </div>
      <div class="siteCont">
        <div class="inner">
          <ul>
            <li v-for="(firDepth, index) in firDepth" :key="index">
              <div class="firDepth">
                <p>{{ firDepth.title }}</p>
              </div>
              <ul class="secDepth">
                <li v-for="(secDepth, index) in firDepth.menuList" :key="index">
                  <router-link :to="secDepth.link" class="secTitle">
                    {{ secDepth.title }}
                  </router-link>
                  <ul class="trdDepth">
                    <li
                      v-for="(trdDepth, index) in secDepth.menuList"
                      :key="index"
                    >
                      <router-link :to="trdDepth.link">
                        {{ trdDepth.title }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <AlertComponent
      @setAlertData="setAlertData"
      ref="AlertComponent"
      :msg="msg"
      :alertType="alertType"
      :url="url"
    />
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import api from '@/api/modules/util';
import AlertComponent from '@/components/AlertComponent.vue';

export default {
  data() {
    return {
      firDepth: [],
      modalShow: false,
      btnmenu: false,
      searchKeyword: '',
      searchBarShow: false,
      siteMap: false,

      isHovering: false,

      msg: '',
      alertType: '',
      url: '',
    };
  },

  components: {
    AlertComponent,
  },

  created() {
    this.getGnbMenuList();
  },

  watch: {
    isLogin() {
      this.getGnbMenuList();
    },
    $route() {
      this.btnmenu = false;
      this.siteMap = false;
    },
  },

  methods: {
    getGnbMenuList() {
      const copy = cloneDeep(this.menuList);
      const recursion = (menu = []) => {
        for (let i = 0; i < menu.length; i++) {
          if (!this.isLogin) {
            if (menu[i].link.includes('mypage')) {
              menu[i] = '';
            }
          } else {
            // 관리자 예외
            if (this.empDiv === 'ADMIN') {
              if (menu[i].link === '/mypage/business') menu[i] = '';
            } else {
              if (this.empDiv === 'BSN') {
                if (menu[i].title === '주차서비스') {
                  menu[i].link = '/parkingpasses/info';
                }
              }
              if (menu[i].auth !== this.empDiv && menu[i].auth !== undefined) {
                menu[i] = '';
              }
            }
          }
          recursion(menu[i].menuList);
        }
      };
      recursion(copy);

      this.firDepth = copy.filter(v => {
        if (v.menuList) {
          v.menuList = v.menuList.filter(i => i != '');
        }
        return v;
      });
    },

    // gnbMenu Open
    openGnbmenu(item) {
      item.showMenu = true;
      this.isHovering = true;
    },

    // gnbMenu Close
    closeGnbmenu(item) {
      item.showMenu = false;
      this.isHovering = false;
    },

    siteMapBtn() {
      this.siteMap = !this.siteMap;
    },

    menubtn() {
      this.btnmenu = !this.btnmenu;
    },

    searchBar() {
      if (this.searchBarShow) this.search();
      this.searchBarShow = true;
    },

    searchBarClose() {
      this.searchBarShow = false;
    },

    async actionLogout() {
      const data = await api.logout();

      if (data) {
        this.$store.dispatch('logout', undefined);
        this.alertData('로그아웃 되었습니다.', 'check', '/main');
        this.$refs.AlertComponent.focusButton();
      } else {
        alert('다시 시도해주세요.');
      }
    },

    alertData(msg, alertType, url) {
      this.msg = msg;
      this.alertType = alertType;
      this.url = url;
    },

    setAlertData() {
      this.alertType = '';
    },

    focused(e) {
      e.target.placeholder = '';
    },

    focusOut(e) {
      e.target.placeholder = '검색어를 입력하세요';
    },

    search() {
      if (this.searchKeyword === '') return;
      this.searchBarShow = false;
      if (search.value !== '') {
        this.$router.push({
          path: '/searchlist',
          query: { q: this.searchKeyword },
        });
      }
      this.searchKeyword = '';
    },
  },

  computed: {
    ...mapGetters({
      menuList: 'getMenuList',
    }),
    isLogin() {
      return this.$store.state.isLogin;
    },
    empDiv() {
      return this.$store.state.empDiv;
    },
    empNm() {
      return this.$store.state.empNm;
    },
  },
};
</script>
