function loadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/board/${view}.vue`
    );
}

const boardRoutes = [
  /* 정보마당 */

  // 사업현황
  {
    path: '/businessinfo',
    name: 'businessinfo',
    component: loadView('businessInfo'),
    meta: {
      category: ['정보마당', '사업현황'],
    },
  },

  // 공지사항
  {
    path: '/noticelist',
    name: 'noticelist',
    component: loadView('noticeList'),
    meta: {
      category: ['정보마당', '공지사항'],
    },
  },
  // 공지사항 - 상세
  {
    path: '/noticeview',
    name: 'noticeview',
    component: loadView('noticeView'),
    meta: {
      category: ['정보마당', '공지사항'],
    },
  },

  // 임대/광고/기타 입찰정보
  {
    path: '/bidlist',
    name: 'bidlist',
    component: loadView('bidList'),
    meta: {
      category: ['정보마당', '임대/광고/기타 입찰정보'],
    },
  },
  // 임대/광고/기타 입찰정보 - 상세
  {
    path: '/bidview',
    name: 'bidview',
    component: loadView('bidView'),
    meta: {
      category: ['정보마당', '임대/광고/기타 입찰정보'],
    },
  },

  // 자주하는 질문
  {
    path: '/faqlist',
    name: 'faqlist',
    component: loadView('faqList'),
    meta: {
      category: ['정보마당', '자주하는 질문'],
    },
  },

  // 조직안내
  {
    path: '/organizationinfo',
    name: 'organizationinfo',
    component: loadView('organizationInfo'),
    meta: {
      category: ['정보마당', '조직안내'],
    },
  },

  // 고객의 소리
  {
    path: '/customer',
    name: 'customer',
    component: () => {
      const link = 'https://www.bisco.or.kr/custom/custom10/';
      if (/Mobi/i.test(window.navigator.userAgent)) {
        location.href = link;
      } else {
        window.open(link, '_blank');
      }
    },
    meta: {
      category: ['정보마당', '고객의 소리'],
    },
  },
];

export default boardRoutes;
