function LoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/parkingService/nonstop/${view}.vue`
    );
}

const nonstopRoutes = [
  /* 주차서비스 > 무정차 정산 */
  // 안내
  {
    path: '/nonstop/info',
    name: 'nonstopinfo',
    component: LoadView('nonstopInfo'),
    meta: {
      category: ['주차서비스', '무정차 정산', '무정차 정산 안내'],
    },
  },
  // 약관
  {
    path: '/nonstop/agreement',
    name: 'nonstopagreement',
    component: LoadView('nonstopAgreement'),
    meta: {
      category: ['주차서비스', '무정차 정산', '무정차 정산 신청'],
      empcategory: ['USR', 'BSN'],
    },
  },
  // 카드등록
  {
    path: '/nonstop/cardregist',
    name: 'nonstopcardregist',
    component: LoadView('nonstopCardregist'),
    meta: {
      category: ['주차서비스', '무정차 정산', '무정차 정산 신청'],
      empcategory: ['USR', 'BSN'],
    },
  },
  // 입력
  {
    path: '/nonstop/confirm',
    name: 'nonstopconfirm',
    component: LoadView('nonstopConfirm'),
    meta: {
      category: ['주차서비스', '무정차 정산', '무정차 정산 신청'],
      empcategory: ['USR', 'BSN'],
    },
  },
  // 신청내역
  {
    path: '/nonstop/history',
    name: 'nonstophistory',
    component: LoadView('nonstopHistory'),
    meta: {
      category: ['주차서비스', '무정차 정산', '무정차 정산 신청내역 확인'],
      empcategory: ['USR', 'BSN'],
    },
  },
  // 이용조회
  {
    path: '/nonstop/usage',
    name: 'nonstopusage',
    component: LoadView('nonstopUsage'),
    meta: {
      category: ['주차서비스', '무정차 정산', '무정차 정산 이용 조회'],
      empcategory: ['USR', 'BSN'],
    },
  },
];

export default nonstopRoutes;
