function LoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/html/subpage/parkingService/parkingPasses/${view}.vue`
    );
}

const passesRoutes = [
  /* 주차서비스 > 주차이용권 */
  // 안내
  {
    path: '/parkingpasses/info',
    name: 'parkingpassesinfo',
    component: LoadView('parkingPassesInfo'),
    meta: {
      category: ['주차서비스', '주차이용권', '주차이용권 안내'],
    },
  },
  // 포인트 구매
  {
    path: '/parkingpasses/buypoint',
    name: 'parkingpassesbuypoint',
    component: LoadView('parkingPassesBuypoint'),
    meta: {
      category: ['주차서비스', '주차이용권', '포인트 구매'],
      empcategory: ['BSN', 'ADMIN'],
    },
  },
  // 포인트 사용
  {
    path: '/parkingpasses/usepoint',
    name: 'parkingpassesusepoint',
    component: LoadView('parkingPassesUsepoint'),
    meta: {
      category: ['주차서비스', '주차이용권', '포인트 사용'],
      empcategory: ['BSN', 'ADMIN'],
    },
  },
  // 포인트 내역
  {
    path: '/parkingpasses/pointhistory',
    name: 'parkingpassespointhistory',
    component: LoadView('parkingPassesPointHistory'),
    meta: {
      category: ['주차서비스', '주차이용권', '포인트 내역'],
      empcategory: ['BSN', 'ADMIN'],
    },
  },
];

export default passesRoutes;
